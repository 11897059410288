body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
font-family: 'Segoe UI Regular';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Regular'), url('fonts/Segoe UI/Segoe UI.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Italic';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Italic'), url('fonts/Segoe UI/Segoe UI Italic.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Bold';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Bold'), url('fonts/Segoe UI/Segoe UI Bold.woff') format('woff');
}


@font-face {
font-family: 'Segoe UI Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Segoe UI Bold Italic'), url('fonts/Segoe UI/Segoe UI Bold Italic.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

