.App {
  min-height: 100vh;
  background-color: #fbfbff; }

.container-header {
  padding-right: 10px !important;
  padding-left: 10px !important; }

.loader-background {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #8d8d8d71;
  display: flex;
  align-items: center; }

footer {
  border-top: 1px solid #D5D0D0;
  position: absolute;
  bottom: 0px;
  min-height: 50px;
  background-color: #FBFBFF;
  font-size: smaller;
  font-family: 'Lato', sans-serif;
  padding-left: 15px;
  padding-right: 15px; }

.footer-link {
  color: #6468c8;
  cursor: pointer; }

.footer-link:hover {
  font-weight: bold;
  text-decoration: none;
  color: #6468c8; }

.footer-row {
  min-height: 50px; }

.cursor-pointer {
  cursor: pointer;
  max-height: 20px; }

.h-100.container-fluid {
  min-height: 50px; }

.header-row {
  min-height: 72px; }

header {
  font-family: 'Lato', sans-serif;
  background-color: #F5F5F5;
  color: #FBFBFF;
  min-height: 72px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1); }

.header-search {
  min-height: 44px;
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
  padding-left: 30px !important; }

.header-search-button {
  background-color: #f3711a !important;
  min-height: 45px;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important; }

.trilhas-mobile-title {
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-weight: lighter;
  max-width: 180px; }

.home-icon-mobile {
  max-height: 12px; }

span {
  display: inline-block; }

.header-icons {
  max-width: 130px !important; }
